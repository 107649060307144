import { useCaseService } from "~/src/services/CaseService";
import {
  abortNavigation,
  createError,
  defineNuxtRouteMiddleware,
} from "#imports";

export default defineNuxtRouteMiddleware(async (to) => {
  const { getCaseCached } = useCaseService();
  const caseId = decodeURIComponent(String(to.params.caseId));
  const result = await getCaseCached(caseId).catch(() => null);

  if (result == null) {
    return abortNavigation(
      createError({
        statusCode: 404,
        message: "Case not found",
      })
    );
  }
});
